.navbar-Items {
  background: linear-gradient(to bottom left, /* #1D2B64, */ #001447, #001447),
    linear-gradient(360deg, #ff5733, #6dab50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0 30px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  /* height: 120px; */
  height: 70px;
  /* border-radius: 15px; */
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translate(-50%);
  z-index: 99999;
}
.phEmail {
  font-size: 20px;
  font-weight: 600;
  color: white;
  margin: 0;
  padding: 0.1rem 1rem;
}

.right-side-cont {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}
.phEmailCont {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.3rem;
}
.solid {
  width: 100%;
  border-top: 1px solid #ffffff;
}

.nav-top {
  flex: 6.1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px;
  /* box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25); */
  width: 100%;
  /* height: 50px; */
}

.nav-bottom {
  flex: 6.5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0 30px;
  /* box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25); */
  width: 100%;
  height: 70px;
  /* border-top: 1px #ffffff solid; */
  /* border-bottom: 1px #ffffff solid; */
  /* border-radius: 15px; */
  /* position: fixed; */
  /* margin-bottom: 1rem; */
}

/* 
.navbar-Items{
    background-color: aqua;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 80px;
    border-radius: 15px;
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translate(-50%);
    z-index: 999;
    

} */

.req-button {
  text-decoration: none;
  /* outline: none; */
  color: white;
  background-color: #ef8931;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.6rem 0.8rem;
  white-space: nowrap;
  text-align-last: center;
}

.contact-button {
  margin-top: 1rem;
  margin-bottom: 0.3rem;
  margin-right: 2rem;
  text-decoration: none;
  /* outline: none; */
  color: white;
  background-color: #ef8931;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.6rem 0.8rem;
  white-space: nowrap;
  text-align-last: center;
  border-radius: 5px;
}

.contact-button:hover {
  background-color: white;
  text-decoration: none;
  /* outline: none; */
  color: #001447;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.6rem 0.8rem;
  white-space: nowrap;
  text-align-last: center;
  cursor: pointer;
  border-radius: 5px;
}
.req-button:hover {
  background-color: rgb(20, 0, 70);
  text-decoration: none;
  /* outline: none; */
  color: white;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.6rem 0.8rem;
  white-space: nowrap;
  text-align-last: center;
  cursor: pointer;
}

.navbar-Logo {
  background-color: white;
  border-radius: 10px;
  font-size: 2rem;
  cursor: pointer;
  font-weight: 700;
  padding: 0.3rem 0.7rem;
  /* margin-left: 40px; */
}
.navbar-Menu {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
}
.nav-Links {
  text-decoration: none;
  /* outline: none; */
  color: white;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.7rem 1rem;
  white-space: nowrap;
  text-align-last: center;
}
.nav-Links i {
  padding-right: 10px;
}

.nav-Links:hover {
  /* outline: none; */
  text-decoration: none;
  background-color: white;
  color: #001447;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.nav-Links-mobile {
  display: none;
}

button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  transition: 0.2s ease-in-out;
}

button:hover {
  color: white;
  background-color: black;
}

.menu-Icons {
  display: none;
  font-size: 25px;
  color: #222;
}

@media screen and (max-width: 1180px) {
  .right-side-cont{
    display: none;
  }
  .navbar-Items {
    z-index: 999;
    background: linear-gradient(to bottom left, /* #1D2B64, */ #fff, #fff),
      linear-gradient(360deg, #ff5733, #6dab50);
    width: 80%;
    border-radius: 10px;
    top: 10px;
  }

  .nav-bottom {
    justify-content: space-between;
  }

  .navbar-Menu {
    display: flex;
    flex-direction: column;
    /* list-style: none; */
    justify-content: flex-start;
    /* align-items: stretch; */
    background-color: white;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -100%;
    align-items: stretch;
    padding: 40px 0px 0px 0px;
    opacity: 0;
    margin: 0;
    border-radius: 13px;
    transition: 0.3s ease-in-out; /*transition out */
  }

  .navbar-Menu.active {
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.3s ease-in-out; /*transition in */
  }

  .nav-Links {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 1rem 0rem;
    text-align-last: center;
    color: #001447;
  }

  .nav-Links:hover {
    background-color: var(--darkblue);
    transition: none;
  }

  .nav-Links-mobile {
    text-align-last: center;
    display: block;
    text-decoration: none;
    color: white;
    background-color: var(--darkblue);
    padding: 20px 0px;
    width: 80%;
    margin: auto;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2px;
  }

  .nav-Links-mobile:hover {
    color: white;
    background-color: black;
    text-decoration: none;
  }

  button {
    display: none;
  }

  .menu-Icons {
    display: block;
    font-size: 25px;
    color: #222;
    cursor: pointer;
  }
}

@media (max-width: 450px) {

  
.req-button {
  text-decoration: none;
  width: 50vw !important;
  /* outline: none; */
  color: white;
  background-color: #ef8931;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.7rem 0.3rem;
  white-space: nowrap;
  text-align-last: center;
}

  .right-side-cont{
    display: none;
  }
  .navbar-Items {
    z-index: 999;
    background: linear-gradient(to bottom left, /* #1D2B64, */ #fff, #fff),
      linear-gradient(360deg, #ff5733, #6dab50);
    width: 90%;
    border-radius: 10px;
    top: 10px;
  }

  .nav-bottom {
    justify-content: space-between;
  }

  .navbar-Menu {
    display: flex;
    flex-direction: column;
    /* list-style: none; */
    justify-content: flex-start;
    /* align-items: stretch; */
    background-color: white;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -100%;
    align-items: stretch;
    padding: 40px 0px 20px 0px;
    opacity: 0;
    margin: 0;
    border-radius: 13px;
    transition: 0.3s ease-in-out; /*transition out */
  }

  .navbar-Menu.active {
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.3s ease-in-out; /*transition in */
  }

  .nav-Links {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 1.5rem 0rem;
    text-align-last: center;
    color: #001447;
  }

  .nav-Links:hover {
    background-color: var(--darkblue);
    transition: none;
  }

  .nav-Links-mobile {
    text-align-last: center;
    display: block;
    text-decoration: none;
    color: white;
    background-color: var(--darkblue);
    padding: 20px 0px;
    width: 80%;
    margin: auto;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2px;
  }

  .nav-Links-mobile:hover {
    color: white;
    background-color: black;
    text-decoration: none;
  }

  button {
    display: none;
  }

  .menu-Icons {
    display: block;
    font-size: 25px;
    color: #222;
    cursor: pointer;
  }
}
