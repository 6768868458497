.mainContainer {
  display: flex;
  flex-direction: column;
  /* height: 210vh; */
  /* margin-bottom: 110vh; */
  /* position: inherit; */
}

.text-solution-cont{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hyperLink-card {
  list-style: none;
  text-decoration: none;
  color: inherit;
}

.mainHeader {
  margin-top: 4%;
  align-self: center;
  font-size: 40px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: var(--darkBlue);
}

.firstRow {
  padding: 2.5rem 0rem 0.5rem 0rem;
  flex: 6;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.secondRow {
  padding: 1rem 12rem 1rem 12rem;
  flex: 6;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.smallBorder {
  align-self: center;
  /* margin-left: 5rem; */
}

.icon {
  align-self: center;
  transform: scale(0.7);
}

.headerText {
  text-align: center;
  align-self: center;
  font-size: 25px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: var(--darkBlue);
  margin-top: -0.1rem;
}

.headerText2 {
  text-align: center;
  align-self: center;
  font-size: 25px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: var(--darkBlue);
  /* margin-top: -1.3rem; */
}

.paraText {
  /* margin-top: -0.5rem; */
  margin: 0;
  margin-left: 4rem;
  text-align: start;
  /* align-self: center; */
  /* justify-self: center; */
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  color: #000000;
  width: 70%;
}

.bullet-text{
  font-size: 22px;
  font-weight: 900;
  margin-left: -1.5rem;
}

.points-cont{
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: start;
  margin-top: 1rem;
}

.paraText2 {
  /* margin-top: -0.5rem; */
  margin: 0;
  margin-left: 1rem;
  text-align: start;
  /* align-self: center; */
  /* justify-self: center; */
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  color: #001447;
  /* width: 100%; */
}

.paraTextBottom {
  /* margin-top: -0.5rem; */
  margin: 0;
  margin-left: 0rem;
  text-align: start;
  /* align-self: center; */
  /* justify-self: center; */
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  color: #001447;
  width: 100%;
}

.hyperLink {
  /* display: flex; */
  text-align: center;
  align-self: center;
  /* justify-content: baseline; */
  font-size: 14px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  color: #ee8022;
  /* margin-top: 1rem; */
  text-decoration: underline;
}

.cardMain {
  display: block;
  position: relative;
  /* max-width: 262px; */
  background-color: #f2f8f9;
  border-radius: 4px;
  /* padding: 32px 24px; */
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -25px;
    right: -25px;
    background: var(--darkBlue);
    height: 60px;
    width: 60px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  &:hover:before {
    transform: scale(21);
  }
}

.cardMain:hover {
  p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }
  h3 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
}

.cardMain {
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  width: 27vw;
  height: 23rem;
  /* background-color: white; */
  background: linear-gradient(to bottom right, #8e9eab, #eef2f3),
    linear-gradient(360deg, #ff5733, #6dab50);
  padding: 0.2rem 1rem 0.2rem 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Add a transition for background color change */
  transform: scale(0.9);
  /* Initial scale down the container */
  /* transform-origin: center; */
  align-items: center;
}

.cardMain:hover {
  background-color: var(--darkBlue);
  /* transform: scale(1); */
}

.cardMain:hover .headerText,
.cardMain:hover .headerText2,
.cardMain:hover .paraText,
.cardMain:hover .paraText2,
.cardMain:hover .bullet-text,
.cardMain:hover .paraTextBottom,
.cardMain:hover .hyperLink {
  color: white;
}

@media screen and (max-width: 1000px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    /* height: 550vh; */
    /* margin-bottom: 110vh; */
    /* position: inherit; */
  }

  .firstRow {
    padding: 2.5rem 0rem 0.5rem 0rem;
    /* flex: 6; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .secondRow {
    padding: 2.5rem 0rem 0.5rem 0rem;
    /* flex: 6; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .cardMain {
    background-color: bisque;
    width: 85vw;
  }

  .mainHeader {
    margin-left: 2rem;
  }

  /* .paraText2{
    margin-left: 30vw;
  } */
}


@media screen and (max-width: 580px) {


  .hyperLink {
    text-align: center;
    align-self: center;
    /* justify-content: baseline; */
    font-size: 14px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    color: #ee8022;
    margin-bottom: 2rem;
    margin-top: 0rem;
    text-decoration: underline;
  }

  /* .paraText2{
    margin-left: 0rem;
    font-size: 17px;
  } */
  .mainHeader {
   
    font-size: 25px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    color: var(--darkBlue);
  }
  .mainContainer {
    display: flex;
    flex-direction: column;
    /* height: 355vh; */
    /* margin-bottom: 110vh; */
    /* position: inherit; */
  }

  .firstRow {
    padding: 0rem 0rem 0rem 0rem;
    /* flex: 6; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .secondRow {
    padding: 0rem 0rem 0rem 0rem;
    /* flex: 6; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .cardMain {
    background-color: bisque;
    width: 85vw;
    height: 24rem;
  }

  .mainHeader {
    margin-left: 0rem;
    /* margin-top: 2rem; */
  }

  .smallBorder{
    width: 15% !important;
  }

 
}
