.hcmContainer{
    margin: 4rem 12rem 2rem 6rem;
}
.hcmContainer h1{
    text-align: start;
    margin-bottom: 1rem;
    font-size: 1.7rem;
    font-family: 'Quicksand', sans-serif;
    color: #033B58;
}
.hcmContainer p{
    text-align: start;
    font-size: 1.2rem;
    font-family: 'Quicksand', sans-serif;
    color: #033B58;
}

.homeIconCont{
    cursor: pointer;
    /* height: 60px; */
    display: inline;
    font-size: 2rem;
    background-color: #EE8022;
    /* font-weight: 500; */
    /* color: white; */
    font-family: 'Quicksand', sans-serif;
    border: none;
    /* padding: 1rem 2rem; */
    /* align-content: center; */
    /* justify-items: center; */
    /* width: 12rem; */
    margin-top: 1rem;
    font-family: 'Quicksand', sans-serif;
    text-decoration: none;
    /* display: inline; */
 
    /* font-weight: 800; */
        color: white;
}
.homeIconCont:hover{
    background-color: var(--darkBlue);
}
.hcmContainer a{
    font-family: 'Quicksand', sans-serif;
    text-decoration: none;
    display: inline;
    font-size: 1.5rem;
    font-weight: 800;
        color: white;
        align-self: center;
}

.normText{
    text-align: start;
    font-size:  1.2rem;
    font-family: 'Quicksand', sans-serif;
    color: #033B58;
    margin: 1rem 12rem 1rem 6rem;
}

@media screen and (max-width:850px) {

    .hcmContainer{
        margin: 3rem 3rem 4rem 3rem;
    }
    
.normText{
    margin: 1rem 3rem 1rem 3rem;
}

.hcmContainer a{
    font-family: 'Quicksand', sans-serif;
    text-decoration: none;
    /* display: inline; */
    font-size: 1rem;
    font-weight: 800;
        color: white;
        align-content: center;
        /* margin-left: -1.5rem; */
}
}

