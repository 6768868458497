.aboutUsMain {
  display: flex;
  /* height: 170vh; */
  flex-direction: column;
}

.aboutUsCentre {
  display: flex;
  flex: 8;
  flex-direction: column;
}

.t-image {
  /* height: 300px; */
  overflow: hidden;
  /* border-radius: 20% 20% 20% 20% / 20% 20% 20% 20% ; */
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
}

.vector-cont-indi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 10rem;
}
.vector-cont-indi span{
  margin-top: 0.5rem;
  align-self: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--darkBlue);
}
.vector-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.vector-img-cont {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 15px;
}

.about-us-centre-in {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex: 8;
  flex-direction: row-reverse;
}

.about-us-centre-in-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex: 8;
  flex-direction: row;
}
.about-us-centre-in-3 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex: 8;
  flex-direction: row-reverse;
}

.about-us-in-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 6;
}

.about-us-in-right {
  flex: 6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-bottom {
  display: flex;
  flex-direction: row;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Quicksand', sans-serif;
}
.wrp {
  width: 100%;
  height: 100px;
  position: relative;
}
.portada {
  width: 100%;
  height: 100%;
  background: url("../../img/pexels-fauxels_2.png");
  background-attachment: fixed;
}
.contenido {
  padding-top: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  background: rgba(0, 0, 11, 0.6);
}
.contenido .info {
  width: 100%;
  max-width: 200px;
  text-align: center;
}
.contenido .info h1 {
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
  /* margin-bottom: 25px; */
  /* letter-spacing: 1px; */
  width: 55rem;
}
.contenido .info h2 {
  color: #fff;
  font-weight: 600;
  font-size: 50px;
  text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 25px;
  letter-spacing: 1px;
}
.contenido .info a {
  display: inline-block;
  text-decoration: none;
  font-size: 19px;
  padding: 10px 25px;
  color: #fff;
  background: #ee8226;
  border-radius: 10px;
  margin-bottom: 40px;
  border: 2px solid transparent;
  transition: all 300ms ease;
  letter-spacing: 0.5px; /*Para separar un poco las letras*/
}
.contenido .info a:hover {
  background: transparent; /*Fondo transparente*/
  border: 2px solid #fff;
}
.curveado {
  position: absolute;
  width: 100%;
  bottom: -10px;
}
.curveado img {
  width: 100%;
}

/* .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
} */

.aboutUsLeft {
  display: flex;
  flex: 2;
}
.aboutUsRight {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  padding: 2rem 4rem;
}

.aboutUsText p {
  width: 27rem;
  font-size: 1rem;
  color: var(--darkBlue);
  font-family: 'Quicksand', sans-serif;
  line-height: 1.7;
  font-weight: 600;
}
.aboutUsText b {
  font-family: 'Quicksand', sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
}
.images-about-us {
  object-fit: contain;
  width: 40%;
  /* height: 20%; */
  /* transform: scale(1); */
}

.illust-img {
  object-fit: contain;
  width: 100%;
  border-radius: 250px 60px 30px 60px;
  padding-left: 2rem;
}
.illust-img-2 {
  object-fit: contain;
  width: 100%;
  border-radius: 70px 250px 30px 60px;
}

/* .imageContainer{
    position: relative;
    top: 4rem;
    left: 0;
} */
.aboutUsHeader {
  margin-top: 2rem;
  /* margin-bottom: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: var(--darkBlue);
}
/* .imageContainer2{
    position: relative;
    top: 12rem;
   right: 10rem;
} */
/* 
.imageContainer3{
    position: relative;
    top: 16rem;
   right: 10rem;
} */

@media screen and (max-width: 1080px) {
  .aboutUsMain {
    display: flex;
    flex-direction: column;
    /* height: 400vh; */
  }

  .aboutUsCentre {
    display: flex;
    flex: 12;
    flex-direction: column;
  }

  .about-us-centre-in {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex: 8;
    flex-direction: row;
  }

  .about-us-centre-in-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex: 8;
    flex-direction: row;
  }

  .about-us-centre-in-3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex: 8;
    flex-direction: row;
  }

  .about-us-in-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 6;
  }

  .about-us-in-right {
    flex: 6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-bottom {
    display: flex;
    flex-direction: row;
  }

  .aboutUsRight {
    display: flex;
    flex-direction: column;
    /* flex: 6 1; */
    padding: 0rem 1rem;
  }
  .aboutUsLeft {
    /* display: flex; */
    /* flex: 8 1; */
  }

  .wrp {
    width: 100%;
    height: 100px;
    position: relative;
  }
  .portada {
    width: 100%;
    height: 100%;
    background: url("../../img/pexels-fauxels_2.png");
    background-attachment: fixed;
  }
  .contenido {
    padding-top: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    background: rgba(0, 0, 11, 0.6);
  }
  .contenido .info {
    width: 100%;
    max-width: 200px;
    text-align: center;
  }
  .contenido .info h1 {
    color: #fff;
    font-weight: 500;
    font-size: 22px;
    text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
    /* margin-bottom: 25px; */
    /* letter-spacing: 1px; */
    width: 80vw;
  }
  .contenido .info h2 {
    color: #fff;
    font-weight: 600;
    font-size: 50px;
    text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
    margin-bottom: 25px;
    letter-spacing: 1px;
  }

  .contenido_button {
    display: inline-block;
    text-decoration: none;
    font-size: 19px;
    padding: 10px 25px;
    color: #fff;
    background: #ee8226;
    border-radius: 10px;
    /* margin-bottom: 40px; */
    border: 2px solid transparent;
    transition: all 300ms ease;
    letter-spacing: 0.5px; /*Para separar un poco las letras*/
  }

  .contenido_button:hover {
    background: transparent; /*Fondo transparente*/
    border: 2px solid #fff;
  }

  .aboutUsRight {
    display: flex;
    flex-direction: column;
    /* flex: 6 1; */
    padding: 0rem 1rem;
  }
  .aboutUsLeft {
    /* display: flex; */
    /* flex: 8 1; */
  }

  /* .aboutUsText {
    width: 95vw;
  } */
}

@media (max-width: 950px) {
  .aboutUsMain {
    display: flex;
    flex-direction: column;
    /* height: 650vh; */
  }

  .aboutUsCentre {
    display: flex;
    flex: 8;
    flex-direction: column;
  }

  .about-us-centre-in {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex: 8;
    flex-direction: column;
  }

  .about-us-centre-in-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex: 8;
    flex-direction: column;
  }

  .about-us-centre-in-3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex: 8;
    flex-direction: column-reverse;
  }

  .about-us-in-right {
    flex: 6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .illust-img {
    object-fit: contain;
    width: 80%;
    border-radius: 250px 60px 30px 60px;
  }
  .illust-img-2 {
    object-fit: contain;
    width: 80%;
    border-radius: 70px 250px 30px 60px;
  }

  .aboutUsText p {
    width: 80vw;
    font-size: 1.2rem;
    color: var(--darkBlue);
    font-family: 'Quicksand', sans-serif;
    line-height: 1.7;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .contenido .info h1 {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
    /* margin-bottom: 25px; */
    /* letter-spacing: 1px; */
    width: 80vw;
  }

  .contenido {
    padding-top: 0rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 11, 0.6);
  }
}

@media (max-width: 450px) {

  .contenido .info {
    width: 100%;
    max-width: 200px;
  }

  .vector-img-cont {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 15px;
  }
  .aboutUsMain {
    display: flex;
    flex-direction: column;
    /* height: 500vh; */
  }

  .aboutUsCentre {
    display: flex;
    flex: 8;
    flex-direction: column;
  }

  .about-us-centre-in {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex: 8;
    flex-direction: column;
  }

  .about-us-centre-in-2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex: 8;
    flex-direction: column;
  }
  .about-us-centre-in-3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex: 8;
    flex-direction: column-reverse;
  }

  .about-us-in-right {
    flex: 6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .illust-img {
    object-fit: contain;
    width: 100%;
    border-radius: 250px 60px 30px 60px;
  }
  .illust-img-2 {
    object-fit: contain;
    width: 80%;
    border-radius: 70px 250px 30px 60px;
  }

  .aboutUsText p {
    width: 80vw;
    font-size: 1rem;
    color: var(--darkBlue);
    font-family: 'Quicksand', sans-serif;
    line-height: 1.7;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .contenido .info h1 {
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
    /* margin-bottom: 25px; */
    /* letter-spacing: 1px; */
    width: 80vw;
  }

  .contenido {
    padding-top: 0rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 11, 0.6);
  }
}
