.tieUpMainCont {
    display: flex;
    height: 57vh;
    flex-direction: column;
    /* align-items: center; */
    /* background-color: aqua; */
    justify-content: center;
    align-items: center;
}

.firstImageStyle{
    width: 10%;
    object-fit: contain;
}

.secondImageStyle{
    width: 10%;
    height: 80%;
    object-fit: contain;
    padding-right: 4rem;
}
.thirdImageStyle{
    width: 10%;
    height: 60%;
    object-fit: contain;
    padding-right: 4rem;
}


/* .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
}  */


.box-t {
    width: 96vw;
    /* padding: 0rem 2rem 0rem 2rem; */
    display: flex;
    flex-direction: column;
    /* justify-self: center; */
    height: 50vh;
    /* background-color: aquamarine; */
    box-t-shadow: 0 0 20px rgba(0, 0, 0, 0.4) ;
    /* border-radius: 50%; */
    /* border-radius: 50%; */
    /* padding-top: 1rem; */

}

.firstRowing {
    padding: 2.5rem 0rem 0.5rem 0rem;
    flex: 4;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.secondRowing {
    padding: 0.7rem 0rem 0rem 2rem;
    flex: 6;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.thirdRowing {
    display: flex;
    flex-direction: row;
    /* background-color: chartreuse; */
    flex: 6;
    justify-content: center;
    /* align-items: center; */
    /* height: 16.66vh; */
}

.box-t img:hover{
    transform: scale(1.4);
    transition: 0.3s ease-in-out;
}


.box-t img{
    transform: scale(1);
    transition: 0.3s ease-in-out;
}



@media screen and (max-width:540px) {
    
.box-t img{
    transform: scale(1.8);
    transition: 0.3s ease-in-out;
}


.firstRowing {
    padding: 0rem 0rem 0.rem 0rem;
    flex: 4;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.secondRowing {
    padding: 0rem 0rem 0rem 0rem;
    flex: 6;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.thirdRowing {
    display: flex;
    flex-direction: row;
    /* background-color: chartreuse; */
    flex: 6;
    justify-content: space-around;
    /* align-items: center; */
    /* height: 16.66vh; */
}


.secondImageStyle{
    width: 10%;
    height: 80%;
    object-fit: contain;
    padding-right: 0rem;
}
.thirdImageStyle{
    width: 10%;
    height: 60%;
    object-fit: contain;
    padding-right: 0rem;
}
}

