.whyUsMain{
    display: flex;
    height: 120vh;
    margin-top: 20vh;

}

/* .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
} */

.whyUsLeft{
    display: flex;
    flex: 8;
}
.whyUsRight{
    display: flex;
    flex-direction: column;
    flex: 6;
    padding: 2rem 4rem;
}

.whyUsText{
    width: 35vw;
    font-size: 1.1rem;
    color: var(--darkBlue);
    font-family: 'Quicksand', sans-serif;
    line-height: 1.6;
    font-weight: 900;
}
.whyUsText b{
    font-family: 'Quicksand', sans-serif;
    font-size: 1.3rem;
    font-weight: 700;
}

/* .imageContainer{
    position: relative;
    top: 4rem;
    left: 0;
} */
.whyUsHeader{
    font-size: 40px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    color: var(--darkBlue);
}
/* .imageContainer2{
    position: relative;
    top: 12rem;
   right: 10rem;
} */
/* 
.imageContainer3{
    position: relative;
    top: 16rem;
   right: 10rem;
} */

@media screen and (max-width : 900px) {
    .whyUsMain{
        display: flex;
        flex-direction: column-reverse;
        height: 220vh;
        margin-top: 0rem;
    
    }

    .whyUsLeft{
        display: flex;
        /* flex: 8; */
    }
    .whyUsRight{
        display: flex;
        flex-direction: column;
        /* flex: 6; */
        padding: 1rem 1rem;
    }

    
.whyUsText{
    width: 85vw;
    font-size: 1.1rem;
    color: var(--darkBlue);
    font-family: 'Quicksand', sans-serif;
    line-height: 1.5;
    font-weight: 900;
}


    
}