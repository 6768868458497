.mainContainer-c {
  display: flex;
  flex-direction: column;
  /* height: 210vh; */
  /* margin-bottom: 110vh; */
  /* position: inherit; */
}

.readmore{
  font-size: 14px;
  font-weight: 600;
  color: #ee8022;
}

/* 
.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
} */

.mainHeader-c {
  margin-top: 4%;
  align-self: center;
  font-size: 40px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: var(--darkBlue);
}

.cardMain-c-job{
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  width: 25rem;
  /* height: 50vh; */
  /* background-color: white; */
  background: linear-gradient(to bottom right, #0f0c29, #302b63,#24243e),
    linear-gradient(360deg, #ff5733, #6dab50);
  /* padding: 0.2rem 1rem 0.2rem 1rem; */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Add a transition for background color change */
  transform: scale(0.9);
  /* Initial scale down the container */
  /* transform-origin: center; */
  align-items: center;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
}

.cardMain-c-job:hover{
  background: linear-gradient(to bottom right, #43C6AC, #191654),
  linear-gradient(360deg, #ff5733, #6dab50);
}

.cardMain-c-job .headerText-c{
  color: white;
}

.cardMain-c-job .paraText-c{
  color: white;
}

.careers-content {
  display: flex;
  flex-direction: column;
  padding: 0rem 15rem;
}

.careers-content h1 {
  font-size: 27px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  padding: 2rem 0rem;
}
.careers-content p {
  text-wrap: wrap;
  width: 50rem;
  font-size: 20px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
}

.firstRow-c {
  padding: 2.5rem 0rem 0.5rem 0rem;
  flex: 6;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.secondRow-c {
  padding: 1rem 15rem 1rem 15rem;
  flex: 6;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.smallBorder-c {
  align-self: center;
  /* margin-left: 5rem; */
}

.icon-c {
  align-self: center;
  transform: scale(0.7);
}

.headerText-c {
  text-align: start;
  align-self: center;
  font-size: 23px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  color: var(--darkBlue);
  padding: 1rem 0rem 0rem 0rem;
  margin: 0px;
  /* margin-top: -0.1rem; */
}

.headerText2-c {
  text-align: center;
  align-self: center;
  font-size: 25px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: var(--darkBlue);
  /* margin-top: -1.3rem; */
}

.paraText-c {
  /* margin-top: -0.5rem; */
  margin: 0;
  /* margin-left: 4rem; */
  text-align: start;
  /* align-self: center; */
  /* justify-self: center; */
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  color: #000000;
  /* width: 70%; */
  padding: 1rem 0.5rem 2rem 1rem;
  align-self: center;
  justify-self: center;
}

.hyperLink-c {
  text-align: center;
  align-self: center;
  font-size: 14px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  color: #ee8022;
  margin-top: 2rem;
  text-decoration: underline;
}
/* 
.cardMain-c {
  display: block;
  position: relative;
  max-width: 262px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -25px;
    right: -25px;
    background: var(--darkBlue);
    height: 60px;
    width: 60px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  &:hover:before {
    transform: scale(21);
  }
}

.cardMain-c:hover {
  p {
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);
  }
  h3 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
} */

.cardMain-c {
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  width: 25rem;
  /* height: 50vh; */
  /* background-color: white; */
  background: linear-gradient(to bottom right, #8e9eab, #eef2f3),
    linear-gradient(360deg, #ff5733, #6dab50);
  /* padding: 0.2rem 1rem 0.2rem 1rem; */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Add a transition for background color change */
  transform: scale(0.9);
  /* Initial scale down the container */
  /* transform-origin: center; */
  align-items: center;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
}

/* .cardMain-c:hover {
  background-color: var(--darkBlue);
  transform: scale(1);
}

.cardMain-c:hover .headerText-c,
.cardMain-c:hover .headerText2-c,
.cardMain-c:hover .paraText-c,
.cardMain-c:hover .hyperLink-c {
  color: white;
} */



@media screen and (max-width: 1080px) {
  .mainContainer-c {
    display: flex;
    flex-direction: column;
    /* height: 440vh; */
    /* margin-bottom: 110vh; */
    /* position: inherit; */
  }

  .cardMain-c{
    height: 15rem;
  } 
  .cardMain-c-job{
    /* height: 15rem; */
    width: 85vw;
  } 

  .cardMain-c-job .paraText-c{
    padding: 1rem 0.5rem 2rem 1rem;
  }
  

  
.mainHeader-c {
  margin-top: 4%;
  align-self: center;
  font-size: 40px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: var(--darkBlue);
}

.careers-content {
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
}

.careers-content h1 {
  font-size: 27px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  padding: 2rem 0rem;
}
.careers-content p {
  text-wrap: wrap;
  width: 80vw;
  font-size: 20px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
}

  .firstRow-c {
    padding: 2.5rem 0rem 0.5rem 0rem;
    /* flex: 6; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .secondRow-c {
    padding: 2.5rem 0rem 0.5rem 0rem;
    /* flex: 6; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  

  .mainHeader-c {
    margin-left: 2rem;
  }

  .careers-content p {
    text-wrap: wrap;
    width: 90vw;
    font-size: 20px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
  }
}

@media screen and (max-width: 750px) {

  
.paraText-c {
  /* margin-top: -0.5rem; */
  margin: 0;
  
  /* margin-left: 4rem; */
  text-align: start;
  /* align-self: center; */
  /* justify-self: center; */
  font-size: 15px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  color: #000000;
  /* width: 70%; */
  padding: 1rem 0.5rem 0rem 1rem;
  align-self: center;
  justify-self: center;
}
  .mainContainer-c {
    display: flex;
    flex-direction: column;
    /* height: 440vh; */
    /* margin-bottom: 110vh; */
    /* position: inherit; */
  }

  .cardMain-c{
    height: 15rem;
  } 
  .cardMain-c-job{
    width: 85vw;
  } 



  
.mainHeader-c {
  margin-top: 4%;
  align-self: center;
  font-size: 40px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: var(--darkBlue);
}

.careers-content {
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
}

.careers-content h1 {
  font-size: 22px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  padding: 2rem 0rem;
}


  .firstRow-c {
    padding: 2.5rem 0rem 0.5rem 0rem;
    /* flex: 6; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .secondRow-c {
    padding: 2.5rem 0rem 0.5rem 0rem;
    /* flex: 6; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .cardMain-c {
    background-color: bisque;
    width: 85vw;
  }

  .mainHeader-c {
    margin-left: 2rem;
  }

  .careers-content p {
    text-wrap: wrap;
    width: 90vw;
    font-size: 16px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
  }

  .smallBorder-c{
    width: 15% !important;
  }
}

