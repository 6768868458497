
.card-x {
  position: relative;
  width: 300px;
  height: 400px;
  background: #fff;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
}

.space{
  padding: 1rem;
}

.img-bx-x {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  transform: translateY(30px) scale(0.5);
  transform-origin: top;
}

.img-bx-x img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-x {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 30px;
}

.content-x .detail-x {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.content-x .detail-x h2 {
  color: #444;
  font-size: 1.5em;
  font-weight: 600;
}

.content-x .detail-x h2 span {
  font-size: 0.6em;
  color: #0379b0;
  font-weight: bold;
}

.sci-x {
  position: relative;
  display: flex;
  margin-top: 5px;
}

.sci-x li {
  list-style: none;
  margin: 4px;
}

.sci-x li a {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: transparent;
  font-size: 1.4em;
  color: #444;
  text-decoration: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: 0.5s;
}

.sci-x li a:hover {
  background: var(--darkBlue);
  color: #fff;
}


.main-exec {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: row;
}
.exec-header {
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  text-align: center;
  font-size: 40px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: var(--darkBlue);
  /* text-decoration: underline; Add underline to the text */
}

.exec-left {
  display: flex;
  flex: 1.5;
}

.exec-center {
  display: flex;
  flex: 8;
  flex-direction: row;
}

.exec-right {
  display: flex;
  flex-direction: column;
  flex: 1.5;
}

.exec-left-in {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 6;
}

.exec-right-in {
  flex: 6;
  display: flex;
  justify-content: center;
  align-items: center;
}

@import url("https://fonts.googleapis.com/css?family=Roboto:400,400i,700");
/* body {
  font-family: Roboto, sans-serif;
  margin: 0;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-items: center;
  background-image: linear-gradient(to top, #96fbc4 0%, #f9f586 100%);
} */
.card-ex {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.3);
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  position: relative;
}
.card-ex h2 {
  text-align: center;
  margin: 0;
  padding: 0 0rem;
}
.card-ex .title-ex {
  padding: 1rem;
  text-align: center;
  color: var(--darkBlue);
  font-weight: bold;
  font-size: 14px;
  /* width: 5rem; */
}
.card-ex .desc-ex {
  padding: 0.5rem 0.2rem;
  font-size: 13px;
}
.card-ex .actions-ex {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0.5rem 1rem;
}
.card-ex svg {
  width: 85px;
  height: 85px;
  margin: 0 auto;
}

.img-avatar-ex {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
  border: 6px solid white;
  background-image: linear-gradient(-60deg, #16a085 0%, #f4d03f 100%);
  top: 15px;
  left: 85px;
}

.card-text-ex {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.title-total-ex {
  /* padding: 2.5em 1.5em 1.5em 1.5em; */
  padding: 0.5em 0.5em 0.5em 4em;
}

path {
  fill: white;
}

.img-portada-ex {
  width: 100%;
}

.portada-ex {
  width: 140%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-image: url("../../img/profilePic1.png");
  background-position: bottom center;
  background-size: cover;
}
.portada-ex-2 {
  width: 140%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-image: url("../../img/profilePic2.jpg");
  background-position: bottom center;
  background-size: cover;
}

.button-exec {
  display: flex;
  width: 10rem;
  justify-content: center;
  border: none;
  background: none;
  font-size: 24px;
  color: var(--darkBlue);
  cursor: pointer;
  transition: 0.5s;
  /* &:hover {
    color: white;
    transform: rotate(22deg);
  } */
}



@media screen and (max-width: 1080px) {
  

  .exec-center{
    flex-direction: column;
  }
  .card-ex{
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 500px) {

  .title-total-ex {
    /* padding: 2.5em 1.5em 1.5em 1.5em; */
    padding: 0.5em 0.5em 0.5em 0.5em;
  }

  .card-text-ex{
    display: flex;
    flex-direction: column;
    height: 40rem;
  }
  

  .card-ex{
    margin: 1rem;
    transform: none !important;
    transition: none !important;
    opacity: 1 !important;
  }
  .exec-left{
    flex: 0;
  }
  .exec-right{
    flex: 0;
  }
  .portada-ex{
    width: 95%;
    transform: scale(0.7);
  }
  .portada-ex-2{
    width: 95%;
    transform: scale(0.7);
  }


 
}
