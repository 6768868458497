.whoWeAreMain{
    display: flex;
    height: 100vh;

}
.weImage{
    width: 15%;
    object-fit: contain;
    margin-top: -1rem;
    margin-bottom: 0.4rem;
}

/* .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
} */

.whoWeAreLeft{
    display: flex;
    flex: 5.6;
    flex-direction: column;
    height: 120vh;
    
    
}
.whoWeAreRight{
    display: flex;
    flex-direction: column;
    flex: 5;
    padding: 2rem 3rem;
    height: 109vh;
}

.whoWeAreText{
    width: 40vw;
    font-size: 1rem;
    color: var(--darkBlue);
    font-family: 'Quicksand', sans-serif;
    line-height: 1.5;
    font-weight: 900;
}
.pointContainer{
    margin-left: 5vh;
    width: 35vw;
    font-size: 1rem;
    color: var(--darkBlue);
    font-family: 'Quicksand', sans-serif;
    line-height: 1.5;
    font-weight: 900;
}
.whoWeAreText b{
    font-family: 'Quicksand', sans-serif;
    font-size: 1.3rem;
    font-weight: 700;
}

/* .imageContainer{
    position: relative;
    top: 4rem;
    left: 0;
} */
.whoWeAreHeader{
    font-size: 40px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    color: var(--darkBlue);
}
/* .imageContainer2{
    position: relative;
    top: 12rem;
   right: 10rem;
} */
/* 
.imageContainer3{
    position: relative;
    top: 16rem;
   right: 10rem;
} */


@media screen and (max-width:900px) {
    .whoWeAreMain{
        flex-direction: column;
        height: 200vh;
    }
    .whoWeAreRight{
        display: flex;
        flex-direction: column;
        /* flex: 5; */
        padding: 1rem 1rem;
        height: 110vh;
    }
    
    .whoWeAreLeft{
        display: flex;
        /* flex: 5.6; */
        /* flex-direction: column; */
        height: 80vh;
        
        
    }
    
.whoWeAreText{
    width: 90vw;
    font-size: 1rem;
    color: var(--darkBlue);
    font-family: 'Quicksand', sans-serif;
    line-height: 1.5;
    font-weight: 900;
}
.pointContainer{
    margin-left: 1rem;
    margin-right: 1rem;
    width: 75vw;
    font-size: 1rem;
    color: var(--darkBlue);
    font-family: 'Quicksand', sans-serif;
    line-height: 1.4;
    font-weight: 900;
}
}