.achievementsMain-d2 {
  display: flex;
  flex-direction: row;
  padding: 4rem 0rem;
}

.main-donation-d2 {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* height: 100vh; */
  align-self: center;
  background: linear-gradient(to bottom right, #001447, #001447),
    linear-gradient(360deg, #ff5733, #6dab50);
}

.achievementHeading-d2 {
  padding: 0rem;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  color: wheat;
}

.box-container {
  /* padding-top: 2rem; */
  display: flex;
  justify-content: center;
  flex: 6.5;
}

.Header-in-swiper {
  font-size: 25px;
  color: white;
  padding-top: 2rem;
  text-align: center;
}

.image_wrapper {
  margin: 5px 10px;
}

.image_wrapper img {
  width: 90%;
  border-radius: 15px;
}

.box {
  cursor: pointer;
  border-radius: 50px;
  position: relative;
  /* top: 55%;
  left: 75%; */
  /* transform: translate(-50%, -50%); */
  width: 500px;
  height: 280px;
  background: var(--darkBlue);
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 20px 50px var(--darkBlue);
  /* border: 2px solid #f09242; */
  color: white;
  padding: 20px;
}

.box:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.5s;
  pointer-events: none;
}

.box:hover:before {
  left: 0%;
  transform: skewX(0deg);
}

.box .content {
  border-radius: 20px;
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  /* border: 1px solid #f0a591; */
  padding: 10px;
  text-align: center;
  /* box-shadow: 0 5px 10px rgba(9, 0, 0, 0.5); */
}

.box span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
}

.box span:nth-child(1) {
  transform: rotate(0deg);
}

/* .box span:nth-child(2) {
  transform: rotate(90deg);
} */

.box span:nth-child(3) {
  transform: rotate(180deg);
}
/* 
.box span:nth-child(4) {
  transform: rotate(270deg);
} */

.box span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  /* background: white; */
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  50.1% {
    transform: scaleX(1);
    transform-origin: right;
  }

  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}

.overlay {
  position: absolute; /* Fixed position to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  /* 105+70  here adding overlay for throughout*/
  height: 43rem;
  background-color: rgba(
    3,
    133,
    255,
    0.374
  ); /* Semi-transparent black background */
  z-index: 0; /* Adjust the z-index to ensure it's above other content */
  pointer-events: none; /* Allows interaction with elements beneath the overlay */
}

.achieveOverlay-d2 {
  display: flex;
  position: relative; /* Fixed position to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black background */
  z-index: 0; /* Adjust the z-index to ensure it's above other content */
  pointer-events: none; /* Allows interaction with elements beneath the overlay */
}

.learnButton {
  cursor: pointer;
  /* height: 80px; */
  display: inline;
  font-size: 1.5rem;
  background-color: #ee8022;
  font-weight: 600;
  color: white;
  font-family: "Quicksand", sans-serif;
  border: none;
  padding: 0.7rem 2rem;
  /* align-items: center; */
  width: 35%;
  margin-top: 1rem;
  border-radius: 10px;
}

.learnButton p {
  align-self: center;
  font-size: 1.8rem;
}

/* Add WA floating button CSS */
.floating {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 40px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  /* justify-content: center; */
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.learnButton:hover {
  /* height: 60px; */
  /* display: inline; */
  /* font-size: 1.8rem; */
  background-color: white;
  color: #001447;
  /* font-weight: 300; */
  /* font-family: 'Quicksand', sans-serif; */
  border: none;
}

.achievementLeft-d2 {
  display: flex;
  flex-direction: row;
  flex: 7;
  align-items: center;
  /* background-color: aqua; */
  /* justify-content: center; */
  /* margin-bottom: 2rem; */
  margin-left: 5rem;
}
.achievementRight-d2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 6.5;
  justify-content: center;
  /* background-color: #d0ced2; */
}

.statusContainer-d2:hover {
  transform: scale(1.3);
  transition: 0.3s ease-in-out;
}
/* .achievementRight-d2 img:hover {
  transform: scale(0.9);
  transition: 0.3s ease-in-out;
} */

.statusContainer1-d2 {
  /* background-color: red; */
  display: flex;
  height: 25rem;
  width: 7rem;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* padding: 0rem 0rem 2rem 0rem; */
  /* align-self: flex-start; */
  /* margin-left: 5rem; */
}

.statusContainer2-d2 {
  /* background-color: antiquewhite; */
  display: flex;
  flex-direction: column;
  /* width: 40rem; */
  padding: 1.5rem;
  height: 20rem;
  justify-content: space-evenly;
  z-index: 0;
}
.statusTexts-d2 {
  display: flex;
  flex-direction: column;
  padding: 0rem 0rem 0rem 0rem;
}
.statusIcon-d2 {
  padding: 1rem 0rem 0rem 0rem;
}

.statusContainer2 {
  /* background-color: antiquewhite; */
  display: flex;
  flex-direction: column;
  /* width: 5rem; */
  padding: 1rem;
  height: 20rem;
  justify-content: space-evenly;
}
.statusTexts {
  display: flex;
  flex-direction: column;
  padding: 0rem 0rem 0rem 2rem;
}
.statusIcon {
  padding: 1rem 0rem 0rem 0rem;
}

.statusHeader-h {
  font-size: 45px;
  font-weight: bold;
  color: white;
}

.main-h-achievement {
  display: flex;
  flex-direction: row;
  flex: 6.3;
  align-items: center;
  /* background-color: aqua; */
  justify-content: center;
  margin-left: 6rem;
  /* padding-bottom: 5rem; */
}

.statusPara-h {
  margin: 0;
  padding: 0rem 0rem 0rem 0rem;
  font-size: 22px;
  font-weight: 500;
  color: white;
  font-family: "Quicksand", sans-serif;
}

.statusHeader-d2 {
  font-size: 38px;
  line-height: 1.5;
  font-weight: bold;
  color: white;
}

.statusPara-d2 {
  margin-top: 0.5rem;
  padding: 0rem 0rem 0rem 0rem;
  font-size: 18px;
  font-weight: 700;
  color: wheat;
  font-family: "Quicksand", sans-serif;
}

.left-icons-cont {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}

.left-icons-text {
  display: flex;
  flex: 3;
  justify-content: end;
  align-items: center;
}
.left-icons-text h3 {
  padding: 0rem 0rem 0rem 0rem;
  font-size: 18px;
  font-weight: 700;
  color: white;
  font-family: "Quicksand", sans-serif;
}
.left-icons {
  flex: 6;
}
.left-icons img {
  border-radius: 0px;
  height: 100%;
  width: 70%;
  object-fit: contain;
}

.zendesk-image{
  border-radius: 0px;
  height: 70%;
  width: 50%;
  object-fit: contain;
}

@media screen and (max-width: 1080px) {

  .achievementLeft-d2{
    transform: none !important;
    transition: none !important;
    opacity: 1 !important;
  }
  /* .achievementLeft {
          display: flex;
          flex-direction: column;
          flex: 6.3 1;
          align-items: center; */
  /* background-color: aqua; */
  /* justify-content: center;
          margin: 2rem 0rem 2rem 0rem;
      } */

  .statusPara-h {
    margin: 0;
    padding: 0rem 0rem 0rem 0rem;
    font-size: 18px;
    font-weight: 500;
    color: white;
    font-family: "Quicksand", sans-serif;
  }

  .statusHeader-h {
    font-size: 30px;
  }

  .statusIcon i {
    font-size: 18px;
  }
  .main-h-achievement {
    display: flex;
    flex-direction: row;
    flex: 6.3;
    align-items: center;
    /* background-color: aqua; */
    justify-content: center;
    margin-left: 0rem;
    /* padding-bottom: 5rem; */
  }

  .carousal-container {
    /* margin-left: 13vw; */
    align-items: center;
    display: flex;
    /* width: vw; */
    /* height: 100vh; */
    /* border: 10px solid black; */
    /* border-radius: 100px; */
    /* height: 100vh; */
    /* width: 80%; */
  }

  .main-donation-d2 {
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* height: 250vh; */
    align-self: center;
    background: linear-gradient(to bottom right, #001447, #001447),
      linear-gradient(360deg, #ff5733, #6dab50);
  }
  .achievementsMain-d2 {
    display: flex;
    flex-direction: column;
    /* height: 130vh; */
  }
  .statusContainer1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    align-self: flex-start;
    margin-left: 2rem;
  }

  .statusContainer2-d2 {
    /* background-color: antiquewhite; */
    display: flex;
    flex-direction: column;
    /* width: 40rem; */
    padding: 1.5rem;
    /* height: 100vh; */
    justify-content: center;
    z-index: 0;
  }

  .achievementHeading-d2 {
    /* padding-left: 2rem; */
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: 600;
    color: wheat;
  }
}

@media screen and (max-width: 750px) {
  .statusHeader-d2 {
    font-size: 38px;
    line-height: 1.5;
    font-weight: 300;
    color: white;
  }
  .achievementsMain-d2 {
    display: flex;
    flex-direction: row;
    padding: 0rem 0rem;
  }
  /* .achievementLeft {
          display: flex;
          flex-direction: column;
          flex: 6.3 1;
          align-items: center; */
  /* background-color: aqua; */
  /* justify-content: center;
          margin: 2rem 0rem 2rem 0rem;
      } */

  .achievementLeft-d2 {
    display: flex;
    flex-direction: row;
    flex: 7;
    align-items: center;
    /* background-color: aqua; */
    /* justify-content: center; */
    /* margin-bottom: 2rem; */
    margin-left: 0rem;
    margin-top: 4rem;
  }

  .learnButton {
    width: 12rem;
  }
  .achievementsMain-d2 {
    display: flex;
    flex-direction: column;
    /* height: 130vh; */
  }
  /* .statusContainer1{
          display: flex;
          flex-direction: row;
          padding: 1rem;
          align-self: flex-start;
          margin-left: 2rem;
      } */
}

.imgCss {
  border-radius: 25px;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.vidCss {
  border-radius: 10px;
  height: 100%;
  width: 90%;
  object-fit: cover;
}

.carousal-container {
  /* margin-left: 13vw; */
  align-items: center;
  display: flex;
  width: 45vw;
  /* height: 100vh; */
  /* border: 10px solid black; */
  /* border-radius: 100px; */
  /* height: 100vh; */
  /* width: 80%; */
}

.heroMain {
  display: flex;
  /* height: 100vh; */
  /* margin-top: 20px; */
}

/* Change the color and size of pagination dots */
.swiper-pagination-bullet {
  width: 20px !important;
  height: 5px !important;
  border-radius: 5px !important;
  background-color: rgb(159, 160, 159) !important ;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  margin-left: 20px !important;
  /* margin-top: 100px !important; */
}

/* Change the color of active pagination dot */
.swiper-pagination-bullet-active {
  background-color: #f6f8f9 !important;
  opacity: 1;
  transform: scale(2);
}
/* 
.imgContainer {
  position: relative;
} */
/* 
.imgPerfect {
  width: 100%;
  height: 100vh;
  object-fit: cover;
} */

.overlay-retreat {
  position: absolute;
  /* top: 10; */
  left: 0;
  width: 100%;
  height: 42rem;
  background: radial-gradient(circle at top left, rgb(65, 65, 95), transparent),
    radial-gradient(circle at top right, rgb(65, 65, 95), transparent),
    radial-gradient(circle at bottom left, rgb(65, 65, 95), transparent),
    radial-gradient(circle at bottom right, rgb(65, 65, 95), transparent);
  opacity: 0.2;
  /* z-index: 0; */
}

/* 
.overlay-retreat {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

.radial-gradient-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: radial-gradient(
    ellipse at top left,
    rgba(81, 80, 80, 0.389),
    transparent
  );
}

.radial-gradient-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: radial-gradient(ellipse at bottom left, black, transparent);
}

.radial-gradient-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: radial-gradient(ellipse at top left, black, transparent);
}

.radial-gradient-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: radial-gradient(ellipse at top right, black, transparent);
}
/* 
.stackContent {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1;
} */

.stackText {
  font-size: 42px;
  padding: 0rem 4rem;
  color: rgb(255, 255, 255);
  font-family: "Quicksand", sans-serif;
  width: 80%;
  font-weight: 600;
}

.stackText2 {
  font-size: 42px;
  padding: 0rem 4rem;
  color: rgb(255, 255, 255);
  font-family: "Quicksand", sans-serif;
  width: 80%;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .left-icons img {
    border-radius: 10px;
    height: 100%;
    width: 50%;
    object-fit: contain;
  }

  .achievementLeft-d2 {
    display: flex;
    flex-direction: row;
    flex: 7;
    align-items: center;
    /* background-color: aqua; */
    /* justify-content: center; */
    /* margin-bottom: 2rem; */
    margin-top: 5rem;
  }

  .left-icons-text h3 {
    padding: 0rem 2rem 0rem 0rem;
    font-size: 18px;
    font-weight: 700;
    color: white;
    font-family: "Quicksand", sans-serif;
  }
  .overlay-retreat {
    position: absolute;
    /* top: 10; */
    left: 0;
    width: 100%;
    height: 25rem;
    background: radial-gradient(
        circle at top left,
        rgb(65, 65, 95),
        transparent
      ),
      radial-gradient(circle at top right, rgb(65, 65, 95), transparent),
      radial-gradient(circle at bottom left, rgb(65, 65, 95), transparent),
      radial-gradient(circle at bottom right, rgb(65, 65, 95), transparent);
    opacity: 0.2;
    /* z-index: 0; */
  }

  .imgCss {
    border-radius: 5px;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .carousal-container {
    /* margin-left: 13vw; */
    align-items: center;
    display: flex;
    width: 90vw;
    /* height: 100vh; */
    /* border: 10px solid black; */
    /* border-radius: 100px; */
    /* height: 100vh; */
    /* width: 80%; */
  }

  .heroMain {
    display: flex;
    /* height: 100vh; */
    /* margin-top: 20px; */
  }
  .stackText {
    font-size: 30px;
    padding: 2rem 2.5rem;
    color: white;
    font-family: "Quicksand", sans-serif;
    width: 80%;
  }
}



.map-cont {
  margin: 0;
  /* font-style: sans-serif; */
  font-family: "Quicksand", sans-serif;
  background: #010740;
  display: flex;
  flex-direction: row;
  height: 40rem;
}

* {
  box-sizing: border-box;
}
.map-stack {
  display: flex;
  flex-direction: column;
  flex: 6;
  justify-content: center;
  align-items: center;
}
.world-map {
  display: flex;
  flex-direction: column;
  flex: 6.5;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 1em;
  position: relative;
}

.world-map img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.map-cont {
  color: #fff;
  margin: 2rem 0;
  padding: 0 1em;
}

.map-cont p {
  max-width: 500px;
  margin: 1em auto;
  line-height: 27px;
}

.rev {
  display: flex;
  flex-direction: row-reverse;
}

.pin {
  /* background: white; */
  cursor: pointer;
  background: #4362f8;
  position: absolute;
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
}

.pin::before {
  cursor: pointer;
  content: "";
  /* background: #4362f8; */
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: pulse 1.3s ease-in-out infinite;
}

.pin span {
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  left: 1.5em;
  top: 50%;
  font-weight: 600;
  transform: translateY(-50%);
  background: #fff;
  color: #101224;
  border-radius: 3em;
  padding: 0.3em 0.6em;
  font-size: 0.9em;
}

.usa {
  top: 52%;
  left: 24%;
}

.germany {
  top: 43%;
  left: 51%;
}

.sa {
  top: 78%;
  left: 53%;
}

.uae {
  top: 55%;
  left: 58%;
}

.singapore {
  top: 65%;
  left: 73%;
}

.ind {
  top: 61%;
  left: 66.5%;
}

@keyframes pulse {
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(10);
  }
}

@media screen and (max-width: 450px) {

  
.statusContainer2-d2 {
  /* background-color: antiquewhite; */
  display: flex;
  flex-direction: column;
  /* width: 40rem; */
  padding: 1.5rem;
  height: 20rem;
  justify-content: space-evenly;
  z-index: 0;
}

  
.main-donation-d2 {
  padding-top: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 60rem;
  /* align-self: center; */
  background: linear-gradient(to bottom right, #001447, #001447),
    linear-gradient(360deg, #ff5733, #6dab50);
}
  .pin span {
    display: inline-block;
    white-space: nowrap;
    position: absolute;
    left: 1.5em;
    top: 50%;
    font-weight: 600;
    transform: translateY(-50%);
    background: #fff;
    color: #101224;
    border-radius: 3em;
    padding: 0.3em 0.6em;
    font-size: 0.6em;
  }

  .statusPara-h {
    margin: 0;
    padding: 0rem 0rem 0rem 0rem;
    font-size: 15px;
    font-weight: 500;
    color: white;
    font-family: "Quicksand", sans-serif;
  }

  .stackText {
    font-size: 30px;
    padding: 2rem 2rem;
    color: white;
    font-family: "Quicksand", sans-serif;
    width: 80%;
  }

  .left-icons img {
    border-radius: 10px;
    height: 100%;
    width: 90%;
    object-fit: contain;
  }
}
