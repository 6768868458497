.t-wrapper {
  /* padding: 0.3rem 0.3rem; */
  /* height: 120vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 2rem;
  /* margin-bottom: 6rem; */
  margin-top: 4rem;
  /* padding-bottom: 10rem; */
}

.t-heading {
  align-self: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.t-heading > * {
  font-size: 2.5rem;
  font-weight: bold;
}

.t-heading span:nth-of-type(2) {
  color: var(--orange);
}

.blur {
  position: absolute;
  width: 33rem;
  height: 14rem;
  border-radius: 50%;
  /* background: #edd0ff; */
  background: linear-gradient(to bottom right, #094e79, #eef2f3),
      linear-gradient(360deg, #ff5733, #6dab50);
  filter: blur(200px);
  z-index: -9;
  /* margin-top: -50%; */
}

.clients-box{
  display: flex;
  width: 80%;
  /* border-radius: 24px; */

  /* height: 25vh; */padding-bottom: 4rem;
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
  width: 80vw;
  /* height: 23rem; */
  background: rgba(255, 255, 255, 0.29);
  border: 7px solid var(--blueCard);
  box-shadow: var(--boxShadow);
  border-radius: 20px;
  /* position: absolute; */
  /* top: -50%; */
}

.testimonial > img {
  width: 8rem;
  height: 8rem;
  /* border-radius: 100%; */
  object-fit: contain;
  padding-bottom: 2rem;
}

.testi-name{
  margin-top: 2rem;
  align-self: flex-start;
  color: var(--gray);
  font-size: 18px;
}

.testi-designation{
  padding-top: 0.2rem;
  align-self: flex-start;
  color: var(--gray);
  font-size: 18px;
  /* padding-bottom: 2rem; */
}

.testimonial > span {
  color: var(--gray);
  font-size: 18px;
}

.t-wrapper .swiper {
  width: 100%;
  height: 80%;
}

.t-wrapper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.t-wrapper .swiper-pagination-bullet-active {
  background: var(--darkblue);
}

@media screen and (max-width: 800px) {

  .testimonial > span {
    color: var(--gray);
    font-size: 14px;
  }
  .t-wrapper {
    /* height: 75rem; */
    padding: 0;
    justify-content: flex-start;
    /* margin-bottom: -10rem; */
  }

  /* .t-heading > * {
    font-size: 2.2rem;
    font-weight: bold;
    
  } */

  .testimonial > img {
    height: 100px;
    width: 100px;
  }
  .blur {
    overflow: hidden;
  }

  
.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
  width: 80vw;
  height: 22rem;
  background: rgba(255, 255, 255, 0.29);
  border: 7px solid var(--blueCard);
  box-shadow: var(--boxShadow);
  border-radius: 20px;
  /* position: absolute; */
  /* top: -50%; */
}
}

@media screen and (max-width: 550px) {

  
  
.testi-name{
  margin-top: 2rem;
  align-self: flex-start;
  color: var(--gray);
  font-size: 14px;
}

.testi-designation{
  padding-top: 0.2rem;
  align-self: flex-start;
  color: var(--gray);
  font-size: 14px;
  /* padding-bottom: 2rem; */
}
.testimonial {
  margin: 0rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem 0.7rem;
  width: 96vw;
  height: 100%;
  background: rgba(255, 255, 255, 0.29);
  border: 7px solid var(--blueCard);
  box-shadow: var(--boxShadow);
  border-radius: 20px;
  /* position: absolute; */
  /* top: -50%; */
}


 
  .t-wrapper {
    /* height: 75rem; */
    /* padding: 0rem 1rem; */
    justify-content: flex-start;
    /* margin-bottom: -10rem; */
  }

  /* .t-heading > * {
    font-size: 2.2rem;
    font-weight: bold;
    
  } */

  .testimonial > img {
    height: 100px;
    width: 100px;
  }
  .blur {
    overflow: hidden;
  }

  
}
