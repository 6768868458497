.contactContainer {
  display: flex;
  /* height: 120vh; */
  flex-direction: row;
  background-image: url("../../img/pexels-irina-iriser-1366957.jpg");
  /* background-image: url('../../img/Rectangle\ 31.png'); */
  background-size: cover;
}

/* Loader styling */
.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

/* Keyframes for the spinning animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.leftContact img {
    width: 40%;
  }

.input-error {
  border: 2px solid red; /* Add red border for invalid fields */
}

.errorText {
  font-size: 14px;
  font-weight: 800;
  color: red;
  margin-top: 5px; /* Adjust spacing */
}

.leftContact {
  display: flex;
  flex: 6.5;
  flex-direction: column;
  padding: 1rem 2rem 2rem 1rem;
  margin: 4rem 2rem 2rem 4rem;
  cursor: pointer;
  /* background-color: teal; */
}

.rightContact {
  display: flex;
  flex: 6;
  flex-direction: column;
  padding: 1rem 2rem 2rem 0rem;
  margin: 4rem 2rem 2rem 2rem;
  /* background-color: aqua; */
}

.add1 {
  font-size: 20px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding: 0.2rem 0.2rem;
  font-family: "Quicksand", sans-serif;
}
.paraCont {
  margin: 1.5rem 0rem 3rem 0rem;
}

.phHeader {
  font-size: 30px;
  font-weight: 600;
  color: #b589e0;
  margin: 0;
  padding: 0;
}
.time {
  font-size: 20px;
  font-weight: 600;
  color: white;
  margin: 0;
  padding: 0.4rem 0.2rem;
}

.phEmailContainer {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.phEmailText {
  font-size: 20px;
  font-weight: 600;
  color: white;
  margin: 0;
  padding: 0rem 3rem;
}
.rHeader {
  font-size: 40px;
  font-weight: 900;
  color: white;
  margin: 0;
  padding: 0;
}

.formContainer {
  margin: 1rem 0rem 1rem 0rem;
  color: black;
}

.formContainer form {
  /* padding-top: 3rem; */
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.formContainer input {
  border-radius: 10px;
  text-align: start;
  font-family: "Quicksand", sans-serif;
  height: 3rem;
  padding: 0 1rem;
  margin-bottom: 0.5rem;
  font-size: 15px;
  /* border-radius: 10px; */
  /* border: 1px solid #2a2a2a; */
}

.formContainer textarea {
  font-size: 16px;
  font-family: "Quicksand", sans-serif;
  text-align: start;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  /* border: 1px solid #2a2a2a; */
}
/* 
.formContainer button {
   
} */

.btn {
  width: 40%;
  align-self: center;
  cursor: pointer;
  height: 60px;
  display: inline;
  font-size: 1.5rem;
  background-color: #ee8022;
  font-weight: 900;
  color: white;
  font-family: "Quicksand", sans-serif;
  border: none;
  border-radius: 10px;
}

.btnActive {
  border-radius: 10px;
  background-color: #2a9d8f;
  width: 40%;
  align-self: center;
  cursor: pointer;
  height: 60px;
  display: inline;
  font-size: 1.5rem;
  font-weight: 900;
  color: white;
  font-family: "Quicksand", sans-serif;
  border: none;
}
.btnActive:hover {
  background-color: #b589e0;
}
/* 

.btn:hover {
    height: 60px;
    display: inline;
    font-size: 1.8rem;
    background-color: #B589E0;
    color: white;
    font-weight: 900;
    font-family: 'Quicksand', sans-serif;
} */

.consent {
  display: flex;
  flex-direction: row;
  /* width: 70%; */
  align-items: center;
  cursor: pointer;
}
.consentText {
  display: block;
  color: white;
  font-size: 22px;
  margin-left: 10px;
  width: 20rem;
  margin-bottom: 0.5rem;
}
.consent a {
  color: orange;
  font-size: 22px;
}

@media screen and (max-width: 900px) {
    
  .contactContainer {
    flex-direction: column-reverse;
    /* height: 270vh; */
  }
  .leftContact {
    margin: 4rem 2rem 2rem 2rem;
    transform: none !important;
    transition: none !important;
    opacity: 1 !important;
  }
  .rightContact {
    margin: 0rem 2rem 0rem 2rem;
    padding: 1rem 1rem 2rem 0rem;
  }
}

@media screen and (max-width: 450px) {
  .contactContainer {
    flex-direction: column-reverse;
    /* height: 200vh; */
  }
  .leftContact {
    margin: 4rem 2rem 2rem 0.5rem;
    transform: none !important;
    transition: none !important;
    opacity: 1 !important;
  }
  .leftContact img {
    width: 60%;
  }
  .consent {
    /* width: 80%; */
  }

  .consentText {
    color: white;
    font-size: 18px;
    margin-left: 10px;
  }
  .consent a {
    color: orange;
    font-size: 18px;
  }

  .phEmailText {
    padding: 0rem 0.3rem;
  }

  .rightContact {
    margin: 2rem 0.3rem 2rem 0.8rem;
    padding: 1rem 1rem 2rem 0rem;
  }
}
