.footerContainer{
    display: flex;
    height: 10vh;
    /* background-color: #033B58; */
    background-color: var(--darkBlue);
}

.footerLeft{
    display: flex;
    flex: 6.5;
    align-items: center;

}
.footerLeft p{
    color: #C4C4C4;
    font-weight: 700;
    margin-left: 10vw;
}
.footerRight{
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    flex: 6;
    margin-right: 10vw;
    align-items: center;
}
.icon-f{
    padding: 0.5rem 0.4rem;
    color: #EE8022;
    font-size: 32px;
    
}


.icon-f:hover{
    color: white;
    transform: scale(1.3);
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width : 450px) {
    .footerLeft p{
        color: #C4C4C4;
        font-weight: 700;
        margin-left: 10vw;
        font-size: 12px;
    }
}